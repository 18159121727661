// Login
export const GET_CONNECTED = "GET_CONNECTED";
export const GET_CONNECTED_SUCCESS = "GET_CONNECTED_SUCCESS";
export const GET_CONNECTED_ERROR = "GET_CONNECTED_ERROR";

// Logout
export const GET_DISCONNECTED = "GET_DISCONNECTED";
export const GET_DISCONNECTED_ERROR = "GET_DISCONNECTED_ERROR";
export const GET_DISCONNECTED_SUCCESS = "GET_DISCONNECTED_SUCCESS";

// Register
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
